
import { Prop, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DropDownComponent from '@/components/common/Dropdown.vue';
import { Debounce } from 'vue-debounce-decorator';
import { sleep } from '../../../utils/helpers';

@Component({
  components: {
    Dropdown: DropDownComponent,
  },
})
export default class ChartLegendPlaceholder extends mixins() {
  // @Prop({
  //   type: Array,
  //   default() {
  //     return [];
  //   },
  // })
  // selectedValues!: any;

  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  options!: any;

  @Prop({ type: Boolean, default: false }) hideSelectAllOption!: boolean;

  @Prop({ type: String, default: 'Placeholder' }) placeholder!: string;

  @Prop({ type: String, default: '' }) title!: string;

  @Prop({ type: String, default: '' }) trackBy!: string;

  @Prop({ type: String, default: '' }) label!: string;

  localSelectedValues: any = [];



  get selectedValues(){
    return this.options.filter(o => o.selected)
  }

  filterRecords(firstArray, secondArray) {
  const outputArray = [];
  console.log(firstArray);

  for (const item of firstArray) {
    console.log(item);
    const index = secondArray.findIndex(o => o.name === item.name );
    console.log(index);
    if (index === -1) {
      outputArray.push(item);
    }
  }

  for (const item of secondArray) {
    console.log(item);
    const index = firstArray.findIndex(o => o.name === item.name );
    console.log(index);
    if (index === -1) {
      outputArray.push(item);
    }
  }

  return outputArray;
}

  @Debounce(500)
  emitValuesUpdatedEvent() {
    // console.log(this.selectedValues);
    console.log(this.localSelectedValues);
    if (JSON.stringify(this.selectedValues) !== JSON.stringify(this.localSelectedValues)) {
      const emitObjects = this.filterRecords(this.localSelectedValues,this.selectedValues)
      console.log(emitObjects);
      this.$emit('update-selected-values', emitObjects );
      // this.localSelectedValues = this.selectedValues
    }
  }

  handleDelete(option) {
    // this.localSelectedValues = this.localSelectedValues.filter((item) => item !== option);
    this.$emit('update-selected-values', [option]);
    // this.localSelectedValues = this.selectedValues
  }


  async mounted(){
    await sleep(2000)
    this.localSelectedValues = this.selectedValues
  }

  @Watch('selectedValues', {
    immediate: true,
  })
  async onSelectedValuesChange(value) {
    this.localSelectedValues = value;
  }
}
